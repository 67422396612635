<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut :subtitle="`${event.name} ${event.dateStart} - ${event.dateEnd}`">
      <!-- <v-btn color="primary" style="border-radius: 10px" class="mr-2"
        >Crear</v-btn
      > -->
     <div style="display: flex; gap: 20px;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary"  v-on="on" class="mr-1" @click="excelExport('httpOviveReportsOViveTickets','Reporte de Venta por usuario')" dark :loading="loading">
            <v-icon x-small dark class="mr-2">fa-file-excel</v-icon> Reporte de venta por usuario
          </v-btn>
        </template>
        <span>Exportar data a .CSV</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary"  v-on="on" class="mr-1" @click="excelExport('httpOviveReportsTickets','Reporte de boletos vendidos')" dark :loading="loading">
            <v-icon x-small dark class="mr-2">fa-file-excel</v-icon> Reporte de boletos vendidos
          </v-btn>
        </template>
        <span>Exportar data a .CSV</span>
      </v-tooltip>
     </div>
    </headerLayaut>
    <!-- contenido de la pagina -->

    <div class="body-template">
      <v-data-table :headers="getHeaders" :items="allticketsSold" sort-by="createdAt" fixed-header
        :search="$store.state.search" :calculate-widths="true" :loading="loading" sort-desc item-key="id" :footer-props="{
      itemsPerPageOptions: [10, 25, 50, 100, 250],
    }">
        <template v-slot:[`item.isFree`]="{ item }">
          <v-chip small :color="item.isFree ? 'info' : 'grey'" :outlined="!item.isFree">
            <span>
              {{ item.isFree ? "Si" : "No" }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.channel`]="{ item }">
          <v-chip :color="item.channel == 'ochoApp' ? '#ff1544' : '#00cdbc'"
            :light="item.channel == 'ochoApp' ? false : true" small>
            <span>
              <img src="@/assets/O-01.svg" v-if="item.channel == 'ochoApp'" class="mr-1" width="16px" alt="" srcset="">
              <v-icon small v-else color="#000">
                mdi-web
              </v-icon>

              {{ item.channel == 'ochoApp' ? "Ocho App" : "Vive Web" }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="item.status == 'available' ? 'info' : 'error'" small>
            <span>
              {{ item.status | statusFilter }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.shortId`]="{ item }">
          <v-chip outlined @click="copyToClipboard(item.shortId)">
            <span>
              {{ item.shortId }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <v-chip color="primary" small>
            <span>
              {{ item.createdAt | filterDate }}
            </span>
          </v-chip>
        </template>

        <template v-slot:[`item.price`]="{ item }">
          <span>
            {{ item.price | currency }}
          </span>
        </template>

        <template slot="body.append">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon class="ma-4" v-on="on" style="cursor: pointer" color="primary" @click="modalShowColumns = true">
                fas fa-eye-slash
              </v-icon>
            </template>
            <span>Mostrar / Ocultar columnas</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog" scrollable width="500px">
      <v-card>
        <v-card-title>
          <v-btn style="right: 10px; top: 10px" icon color="primary" fab small absolute @click="dialog = false">
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Titulo
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text> </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn color="primary" text style="border-radius: 10px">cancelar</v-btn>
          <v-btn color="primary" style="border-radius: 10px">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SHOW AND HIDE TABLE COLUMNS  -->
    <v-dialog max-width="450" persistent v-if="modalShowColumns" v-model="modalShowColumns" scrollable>
      <show-columns @success="modalShowColumns = false" @cancel="modalShowColumns = false" :headers="headers" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import showColumns from "@/components/show-hide-columns";
import { mapActions, mapState } from "vuex";
import headerLayaut from "@/components/header-layaut.vue";
import { db, fb } from "@/firebase";
import { Parser } from "json2csv";

import axios from 'axios';
export default {
  name: "Page",

  components: {
    showColumns,
    headerLayaut,
  },
  data() {
    return {
      modalShowColumns: false,
      dialog: false,
      loading: false,
      ticketsSold: [],
      customFields: [],
      event: null,
      headers: [
        { text: "Código Ocho", active: true, value: "shortId" },
        { text: "Sección", active: true, value: "ticketName" },
        { text: "Precio", active: true, value: "price" },
        { text: "Cliente", value: "userName", active: true },
        { text: "Celular", value: "userPhone", active: true },
        { text: "Fecha de función", active: true, value: "date" },
        { text: "Código del boleto", active: false, value: "ticketCode" },
        { text: "Código de mesa", active: false, value: "ticketTable" },
        { text: "Código de silla", active: false, value: "ticketChair" },
        // { text: "Código de sección", active: true,value: "sectionCode" },
        { text: "Ciudad", active: true, value: "cityName" },
        { text: "Es gratis", active: true, value: "isFree" },
        { text: "Estado", active: true, value: "status" },
        { text: "Canal de compra", active: true, value: "channel" },
        { text: "Comprado", active: true, value: "createdAt" },
      ],
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "business"]),
    getHeaders() {
      if (this.event.customForm?.active) {
        var array = this.ticketsSold[0]?.customFields.map((e) => {
          return { text: e.label, value: e.key, active: true };
        });

        var header = Object.assign([], this.headers);
        if (array) {
          this.customFields = array;
          for (let i = 0; i < array.length; i++) {
            const element = array[i];
            header.push(element);
          }
        }

        return header.filter((item) => item.active == true);
      } else {
        return this.headers.filter((item) => item.active == true);
      }
    },
    allticketsSold() {
      return this.ticketsSold.map((element) => {
        if (element.customFields?.length > 0) {
          var valueCustom = element.customFields.map((e) => {
            return { key: e.key, value: e.value };
          });
          for (let i = 0; i < valueCustom.length; i++) {
            const custom = valueCustom[i];
            element[custom.key] = custom.value ? custom.value : "No disponible";
          }
        }

        return element;
      });
    },
  },
  filters: {
    statusFilter(status) {
      const stateF = {
        available: "disponible",
        used: "Redimido",
      };
      return stateF[status] || "no disponible";
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.Alert_({
            text: `(${text}) Copiado al portapapeles`,
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "info",
          });
        })
        .catch((err) => console.error("Async: Could not copy text: ", err));
    },
    async excelExport(source,title) {
      this.loading = true;
      let token = await this.getSectionToken();

      let data = {
        eventId: this.selectedEvent[".key"],
        userId: this.user[".key"],
      };

   
      axios
        .post(`${process.env.VUE_APP_EL_OCHO}/${source}`, data, {
          headers: { Authorization: `Basic ${token}` },
          responseType: "blob",
        })
        .then((response) => {
          this.generatingReport = false;
          const { data, headers } = response;
          const fileName = `${title} de ${this.selectedEvent.name}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.Alert_({
            text: `Ocurrio un  error inesperado`,
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-error",
            type: "error",
          });
        });
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    toExcel() {
      const filename = `${this.business.shortName}-${this.event.name}.csv`;
      var fields = [
        { label: "Evento", value: "eventName" },
        { label: "Sección", value: "ticketName" },
        {
          label: "Es gratis",
          value: (row) => {
            try {
              return row.isFree ? 'Si' : 'No'
            } catch (error) {
              return `No disponible`;
            }
          },
        },
        { label: "Cliente", value: "userName" },
        { label: "Celular", value: "userPhone" },
        { label: "Código del boleto", value: "shortId" },
        { label: "Ciudad", value: "cityName" },

        {
          label: "Estado",
          value: (row) => {
            const statusFilter = {
              available: "disponible",
              used: "Redimido",
            };
            try {
              return `${statusFilter[row.status]}`;
            } catch (error) {
              return `No disponible`;
            }
          },
        },
        { label: "Canal de compra", value: "channel" },
        { label: "Precio de Boleto", value: "price" },

        { label: "Fecha de función", value: "date" },
        { label: "Hora de función", value: "time" },
        {
          label: "Fecha de compra",
          value: (row) => {
            try {
              return `${moment(row.createdAt.toDate())
                .tz("America/Tegucigalpa")
                .format("LL")}`;
            } catch (error) {
              return `No disponible`;
            }
          },
        },
      ];
      if (this.event.customForm?.active) {
        var array = this.ticketsSold[0].customFields.map((e, index) => {
          return {
            label: e.label,
            value: (row) => {
              try {
                return `${row.customFields[index].value}`;
              } catch (error) {
                return `No disponible`;
              }
            },
          };
        });
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          fields.push(element);
        }
      }
      if (this.event.extraFees.length && this.user.type == 'sudo') {
        var array = this.event.extraFees.map((e, index) => {
          return {
            label: `${e.extraFeeName} - ${e.extraFeeIsFixed ? 'Fijo' : '%Porcentual'}`,
            active: e.active,
            value: (row) => {
              return e.extraFeeIsFixed ? e.extraFeeSum : row.price * (e.extraFeeSum / 100) || 'no disponible'
            }
          };
        });
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          if (element.active) {
            fields.push(element);
          }

        }

      }
      if (this.user.type == 'sudo') {

        fields.push({
          label: "Tarifa", value: (row) => {
            if (this.event.currentPercentage) {
              return (row.price * (this.event.currentPercentage / 100));
            } else {
              return 0
            }
          }
        })
        fields.push({
          label: "Total a pagar al comercio", value: (row) => {
            if (this.event.extraFees) {

              var price = row.price;
              for (let i = 0; i < this.event.extraFees.length; i++) {
                const element = this.event.extraFees[i];

                if (element.active) {
                  if (element.extraFeeIsFixed) {


                    price = row.price - element.extraFeeSum
                  } else {
                    var resul = row.price * (element.extraFeeSum / 100);
                    price = price - resul;
                  }
                }

              }

            }

            if (this.event.currentPercentage) {
              return price - row.price * (this.event.currentPercentage / 100);
            } else {
              return price
            }
            ;

          }
        })
      }


      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.ticketsSold);

      //console.log(csv);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
  },
  async mounted() {
    this._searchTerm("Boletos vendidos");

    this.visibleSearch(true);

    await this.$binding(
      "ticketsSold",
      db
        .collection("tickets")
        .where("businessId", "==", this.business[`.key`])
        .where("eventId", "==", this.selectedEvent[`.key`])
    );
    await this.$binding(
      "event",
      db
        .collection("events").doc(this.selectedEvent[`.key`])

    );
  },
};
</script>
